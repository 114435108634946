document.addEventListener('DOMContentLoaded', () => {
    const heroVideos = document.querySelectorAll('.js-hero-media');

    heroVideos.forEach((heroVideo) => {
        const heroVideoIframe = heroVideo.querySelector(
            'iframe#hero-video-iframe'
        );

        // Ensure the iframe exists
        if (!heroVideoIframe) {
            return;
        }

        const heroPlayer = new Vimeo.Player(heroVideoIframe);

        heroVideoIframe.addEventListener('load', () => {
            heroPlayer.on('play', () => {
                heroVideo.setAttribute('data-video-action', 'pause');
            });

            heroPlayer.on('pause', () => {
                heroVideo.setAttribute('data-video-action', 'play');
            });
        });

        const toggleButton = heroVideo.querySelector('.js-hero-toggle');

        toggleButton?.addEventListener('click', () => {
            heroPlayer
                .getPaused()
                .then((paused) => {
                    if (paused) {
                        heroPlayer.play();
                        heroVideo.setAttribute('data-video-action', 'pause');
                    } else {
                        heroPlayer.pause();
                        heroVideo.setAttribute('data-video-action', 'play');
                    }
                })
                .catch((error) => {
                    console.error('Error checking play/pause state:', error);
                });
        });
    });
});
